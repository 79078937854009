import { get } from '@ebay/retriever';

$ {
  const modelName = input.modelName;
  const metaName = get(input, 'model.meta.name', '');
  const uxComponentName = get(input, 'model.uxComponentName', '');
  const vaultType = get(input, 'model.modules.VAS_META.schemaVersion.VAULT', '');
}
<if(modelName === 'VAS_LAYER')>
  <parts-installation ...input />
</if>

<if(modelName === 'VAS_SPOKE_ADDONS' || modelName === 'VAS_INTERSTITIAL_ADDONS')>
  <vas-layer ...input />
</if>

<if(modelName === 'VAS_WARRANTY_SPOKE')>
  <mweb-vas-warranty-spoke ...input />
</if>

<if(modelName === 'VAS_SPOKE' && uxComponentName === "VAS_SPOKE_V2")>
  <mweb-vas-warranty-spoke-v2 ...input />
</if>

<if(modelName === 'VAULT_ENROLLMENT_SPOKE')>
  <mweb-vas-vault-spoke ...input />
</if>

<if(modelName === 'VAS_INTERSTITIAL_TIRE_INSTALLATION' || modelName === 'VAS_SPOKE_TIRE_INSTALLATION')>
  <tire-installation ...input />
</if>

<if(metaName === 'VAS_WARRANTY_INTERSTITIAL')>
    <mweb-vas-warranty-interstitial ...input />
</if>

<!-- WOO has 3 modules -->
<if(modelName === 'VAS_INTERSTITIAL' && get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.uxComponentName', '') === "VAS_INTERSTITIAL_V2")>
    <if(vaultType === 'VAULT_NUDGE_REDESIGN')>
      <mweb-vas-vault-interstitial ...input />
    </if>
    <else>
      <mweb-vas-warranty-interstitial-v2 ...input />
    </else>
</if>
