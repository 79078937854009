/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
const pubsub = require('raptor-pubsub');
const { get } = require('@ebay/retriever');
const { appendMissingParams } = require('../../common/utils');
// NOTE: per PM, the tracking is needed for MVP, since BE does not have this. we have to hardcode for now.
const ERR_GENERAL_TRACKING = [
  {
    eventFamily: 'ITM',
    eventAction: 'ACTN',
    actionKind: 'VIEW',
    operationId: '2349624',
    flushImmediately: false,
    eventProperty: {
      moduledtl: '144737',
      sid: 'p2349624.m144737.l147910',
    },
  },
];

// no add item selected when user land on warranty interstitial overlay
module.exports = class {
  onCreate(input) {
    const cta = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections[0].dataItems', []);
    const entries = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.vasForm', []);
    const srt = get(input, 'model.srt', '');
    this.state = {
      cta: cta,
      srt: srt,
      channelId: input.channelId,
      isBusy: false,
      // isBusy: {},
      btnDisabled: false,
      btnClickedIdx: -1,
      error: false,
      errMessage: get(input, 'model.modules.STATUS_MESSAGE', {}),
      addonsSelectionMap: '',
      highAspMsg : false
    };
    this.getDefaultRadioBtn(entries)
    this.proxyCloseInterstitial = this.closeInterstitial.bind(this);
  }

  onMount() {
    // drop page view impression event
    const trackingList = get(this.input, 'model.modules.VAS_INTERSTITIAL_MODULE.meta.trackingList', []);
    if (trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', trackingList);
    }
    window.addEventListener('pageshow', this.proxyCloseInterstitial);
  }

  onDestroy() {
    window.removeEventListener('pageshow', this.proxyCloseInterstitial);
  }

  closeInterstitial(event) {
    const historyTraversal =
      event.persisted || (typeof window.performance !== 'undefined' && window.performance.navigation.type === 2);
    if (historyTraversal) {
      pubsub.channel(this.state.channelId).emit('VAS_CLOSE_INTERSTITIAL');
    }
  }

  onRadioSelected(addonEntry) {
    if (addonEntry) {
      if (addonEntry.action && addonEntry.action.trackingList) {
        pubsub.channel(this.state.channelId).emit('tracking', addonEntry.action.trackingList);
      }
      this.setState('addonsSelectionMap', addonEntry.paramValue)
      this.setStateDirty('addonsSelectionMap');
      if(addonEntry.paramValue === '-1') {
        this.setState('highAspMsg', true);
        this.setStateDirty('highAspMsg');
      } else {
        this.setState('highAspMsg', false);
        this.setStateDirty('highAspMsg');
      }
    }
  }

  handleBtnClick(data, id) {
    let selectedRadioValue = '';
    let selectedServices = {};
    if(data.params) {
      selectedRadioValue = this.state.addonsSelectionMap
      data.params.value = selectedRadioValue;
      selectedServices = {'VAULT' : [selectedRadioValue]};
      data.params.selectedServices = JSON.stringify(selectedServices);
    }

    // add or decline button
    if (data && data.trackingList && data.trackingList.length > 0) {
      pubsub.channel(this.input.channelId).emit('tracking', data.trackingList);
    }
    this.state.isBusy = true;
    this.state.btnDisabled = true;
    this.setStateDirty('isBusy');
    this.setStateDirty('btnDisabled');
    this.state.error = false;
    const params = data.params;
    if(params) {
      if (!params.actionParams) {
        // Duplicate actionParams. It's needed by shopactions to pass params when calling perform actions from vasmp
        params.actionParams = JSON.parse(JSON.stringify(params));
      }
      params.selectedServices = selectedServices;
    }

    // each button data.params has the request body
    if (data.URL) {
      if (data.type && data.type === 'NAV') {
        let url = data.URL;
        if (params) {
          url = appendMissingParams(url, params);
        }
        window.location.href = url;
      }
      else {
        const ajaxUrl = this.getUrl(data.URL);
        $.ajax({
          url: ajaxUrl,
          data: params,
          crossDomain: true,
          beforeSend: function (xhr) {
            xhr.withCredentials = true;
          },
          type: 'POST',
          dataType: 'json',
        })
          .done((resp) => {
            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.handleShopActionDone(resp, params);
          })
          .fail((resp) => {
            pubsub.channel(this.input.channelId).emit('tracking', ERR_GENERAL_TRACKING);

            // Unblock call on Vault CTA error
            if (data && get(data, 'unBlockingOnError', 'false') === 'true' &&  data.rucancel ) {
              window.location.href = data.rucancel;
              return;
            }

            this.resetBtnBusyState();
            this.state.btnDisabled = false;
            this.state.error = true;
          });
      }
    }
  }

  resetBtnBusyState() {
    for (const key in this.state.isBusy) {
      if (Object.prototype.hasOwnProperty.call(this.state.isBusy, key)) {
        this.state.isBusy[key] = false;
      }
    }
    this.setStateDirty('isBusy');
  }

  getUrl(url) {
    let ajaxUrl = url;
    if (url.indexOf('?') < 0) {
      ajaxUrl += '?';
    } else {
      ajaxUrl += '&';
    }
    ajaxUrl = `${get(this.input, 'model.shopActionsViewConfig.host', '')}${ajaxUrl}srt=${get(
      this.input,
      'model.srt',
      ''
    )}${get(this.input, 'model.shopActionsViewConfig.param', '')}`;
    return ajaxUrl;
  }

  handleShopActionDone(response, data) {
    this.state.isBusy = {};
    this.setStateDirty('isBusy');
    const modules = get(response, 'modules', {});
    const errorMsg = get(response, 'modules.STATUS_MESSAGE.message.title.textSpans[0].text', '');
    if (!modules.__isEmpty && !errorMsg) {
      // success returns: { modules: {}}
      this.state.error = false;
      if (response.meta && response.meta.screenFlowDestination && response.meta.screenFlowDestination.URL) {
        let destinationUrl = response.meta.screenFlowDestination.URL;
        if (response.meta.screenFlowDestination.params) {
          destinationUrl = appendMissingParams(destinationUrl, response.meta.screenFlowDestination.params);
        }

        window.top.location.href = destinationUrl;
      }
    } else {
      // error
      pubsub.channel(this.input.channelId).emit('tracking', ERR_GENERAL_TRACKING);

      // Unblock call on Vault CTA error
      if (data && get(data, 'unBlockingOnError', 'false') === 'true' &&  data.rucancel ) {
        window.location.href = data.rucancel;
        return;
      }
      this.state.error = true;
      const status = get(response, 'modules.STATUS_MESSAGE', {});
      if (!status.__isEmpty) {
        // show error msg from response if there
        this.state.errMessage = status;
      } else {
        this.state.errMessage = get(this.input, 'model.modules.STATUS_MESSAGE', {}); // default err msg
      }
    }
  }

  getDefaultRadioBtn(entries) {
     entries.forEach(entry => {
       const radioEntries = entry.entries;
       radioEntries.forEach(radioEntry => {
         if(radioEntry.defaultChoice){
           this.setState('addonsSelectionMap', radioEntry.paramValue)
           this.setStateDirty('addonsSelectionMap');
         }
       });
     });
   }

   onLabelClick(action) {
    if (action && action.trackingList) {
      pubsub.channel(this.state.channelId).emit('tracking', action.trackingList);
    }
  }

};
