$ {
	const { get, has } = require('@ebay/retriever');
	const vasCTA = get(input, 'model.modules.VAS_INTERSTITIAL_CTA.sections', []);
	const title = get(input, 'model.modules.VAS_INTERSTITIAL_MODULE.title', {});
	const modules = get(input, 'model.modules', {});
	const vasForm = get(modules, 'VAS_INTERSTITIAL_MODULE.vasForm', []);
	const additionalTexts= get(modules, 'VAS_INTERSTITIAL_MODULE.additionalTexts', []);
	const itemCard = get(modules, 'VAS_ITEM_CARD_MINIMAL.card', {});
	const vaultRedesignType = get(modules, 'VAS_META.schemaVersion.VAULT', '');
	const vaultType = vaultRedesignType === 'VAULT_NUDGE_REDESIGN'? true : false;
	let isInitFocusSet = false;
}

<if(vaultType)>
	<div class="vas-vault-interstitial-v2" id="vas-interstitial-container">

		<div hidden>
			<svg>
				<symbol viewBox="0 0 24 24" id="icon-attention-filled-24">
					<path fill="#e62048" fill-rule="evenodd" d="M12 0C5.373 0 0 5.373 0 12s5.373 12 12 12 12-5.373 12-12C23.994 5.375 18.625.006 12 0zm0 18a1 1 0 110-2 1 1 0 010 2zm1-5V7a1 1 0 00-2 0v6a1 1 0 002 0z" />
				</symbol>
				<symbol id="icon-spinner-24" viewBox="0 0 24 24" fill="none">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 11.4477 22.4477 11 23 11C23.5523 11 24 11.4477 24 12C24 14.3734 23.2962 16.6935 21.9776 18.6668C20.6591 20.6402 18.7849 22.1783 16.5922 23.0866C14.3995 23.9948 11.9867 24.2324 9.65892 23.7694C7.33115 23.3064 5.19295 22.1635 3.51472 20.4853C1.83649 18.8071 0.693605 16.6689 0.230582 14.3411C-0.232441 12.0133 0.00519943 9.60051 0.913451 7.4078C1.8217 5.21509 3.35977 3.34094 5.33316 2.02236C7.30655 0.703788 9.62663 0 12 0C12.5523 0 13 0.447715 13 1C13 1.55228 12.5523 2 12 2Z" fill="var(--color-spinner-icon-foreground, #E5E5E5)"/>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M14.1805 1.17194C14.3381 0.642616 14.895 0.341274 15.4243 0.498872C17.3476 1.07149 19.0965 2.11729 20.5111 3.54055C21.9257 4.96382 22.9609 6.71912 23.5217 8.64584C23.6761 9.17611 23.3714 9.73112 22.8411 9.88549C22.3108 10.0399 21.7558 9.73512 21.6015 9.20485C21.134 7.59925 20.2715 6.13651 19.0926 4.95045C17.9138 3.76439 16.4563 2.8929 14.8536 2.41572C14.3243 2.25812 14.0229 1.70126 14.1805 1.17194Z" fill="var(--color-spinner-icon-background, #3665F3)"/>
				</symbol>
					<symbol#icon-radio-unchecked-18 viewBox='0 0 18 18'>
						<path d='M9 18A9 9 0 119 0a9 9 0 010 18zM9 1a8 8 0 100 16A8 8 0 009 1z'/>
					</symbol>
					<symbol#icon-radio-checked-18 viewBox='0 0 18 18'>
							<path d='M9 0a9 9 0 110 18A9 9 0 019 0zm0 1a8 8 0 100 16A8 8 0 009 1zm0 3a5 5 0 110 10A5 5 0 019 4z'/>
					</symbol>
				<symbol viewBox="0 0 24 24" id="icon-the-ebay-vault-24">
					<path fill-rule="evenodd" clip-rule="evenodd" d="M17 11c0 1.02-.305 1.967-.828 2.757l1.535 1.536a1 1 0 0 1-1.414 1.414l-1.536-1.535A4.977 4.977 0 0 1 12 16a4.977 4.977 0 0 1-2.757-.828l-1.536 1.535a1 1 0 0 1-1.414-1.414l1.535-1.536A4.977 4.977 0 0 1 7 11c0-1.02.305-1.967.828-2.757L6.293 6.707a1 1 0 0 1 1.414-1.414l1.536 1.535A4.977 4.977 0 0 1 12 6c1.02 0 1.967.305 2.757.828l1.536-1.535a1 1 0 1 1 1.414 1.414l-1.535 1.536C16.695 9.033 17 9.98 17 11Zm-2 0a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"></path>
					<path fill-rule="evenodd" clip-rule="evenodd" d="M1 4a4 4 0 0 1 4-4h14a4 4 0 0 1 4 4v14a4.002 4.002 0 0 1-3 3.874V23a1 1 0 0 1-1 1h-2a1 1 0 0 1-1-1v-1H8v1a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1v-1.126A4.002 4.002 0 0 1 1 18V4Zm4-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2Z"></path>
				</symbol>
			</svg>
		</div>

		<div class='vas-interstitial-header'>
			<if(!title.__isEmpty)>
				<h2 id='vas-interstitial-header-txt' class='vas-interstitial-header-text'><ux-textualdisplay model=title/></h2>
			</if>
		</div>
		<!-- interstitial module -->
		<div class="vas-vault-interstitial-v2__body">

			<div class="vas-vault-item-card">
					<vas-item-card model=itemCard/>
				</div>

			<div class='vas-interstitial-body'>
				<for|form, formIdx| of=vasForm>
					$ const vaultHeading = get(form, 'heading.textSpans', []);
					$ const label = get(form, 'label', {})
					$ const secondaryLabels = get(form, 'secondaryLabels', []);
					$ const entries = get(form, 'entries', []);

					<div class='vas-divider' />

					<!-- vault icon & The ebay Vault -->
					<div class="vas-heading">
						<span class="icon-vault" >
							<ebay-the-ebay-vault-24-icon/>
						</span>
						<span class="heading-text">
							<for|heading, index| of=vaultHeading>
								${heading.text}
							</for>
						</span>
					</div>

					<ux-textualdisplay model=label/>

					<!-- Learn More link -->
					<for|label, labelIdx| of=secondaryLabels>
						<div class="vas-secondary-labels">
							<vasLabel label=label name='secondaryLabels' index=labelIdx />
						</div>
					</for>

					<fieldset>
						<for|entry,idx| of=entries>
							<span class="field">
								<ebay-radio
									class="field__control"
									id=`group-radio-${entry.paramKey}-${entry.paramValue}`
									value=state.addonsSelectionMap
									name=entry.paramKey
									type="radio"
									checked =(state.addonsSelectionMap === entry.paramValue)
									on-change("onRadioSelected",entry)
									/>
								<label
									class="field__label field__label--end"
									for=`group-radio-${entry.paramKey}-${entry.paramValue}`>
										<ux-textualdisplay model=entry.label />
								</label>
							</span>
							<div class="field-secondory-label">
								$ const secndaryLabel = get(entry, 'secondaryLabel', {})
									<ux-textualdisplay model=secndaryLabel />
							</div>
						</for>
					</fieldset>
				</for>

				<for|form, formIdx| of=vasForm>
					$ const entries = get(form, 'entries', []);
					$ const highAspNotice = get(entries[1], 'message', {});
					<for|entry,idx| of=entries>
							$ const dataItems = get(entry, 'priceLines[0].dataItems', [])
							<if(state.addonsSelectionMap === entry.paramValue)>
								<for|value, index| of=dataItems>
									<vasLabelsValues model=value index=index />
								</for>
							</if>
					</for>

					<!-- High ASP Notice -->
					<if(Array.isArray(entries) && entries[1] && entries[1].message && state.highAspMsg)>
						<div class="high-asp">
							<ebay-section-notice
								a11y-text=get(highAspNotice, "additionalText[0].accessibilityText","")>
								<ux-textualdisplay model=(get(highAspNotice, 'additionalText[0]', {})) />
							</ebay-section-notice>
						</div>
					</if>
				</for>

			</div>

			<!-- Footer content -->
			<div class='vas-interstitial-footer'>
				<div class='vas-interstitial-footer-section'>
					<for|additionalText, additionalTextIdx| of=additionalTexts>
					$ const dataItems = get(additionalText, 'dataItems', []);

					<ux-textualdisplay model=(additionalText) />
						<for|dataItem, dataItemIdx| of=dataItems>
							<ux-textualdisplay model=dataItem />
						</for>
					</for>
				</div>

				<div class='vas-interstitial-footer-cta'>
					<for|entry, index| of=state.cta>
						<renderVasFooterDataItem model=entry index=index/>
					</for>
				</div>
			</div>
		</div>
	</div>
</if>

<macro|{ label, name, index }| name="vasLabel">
	<if(label)>
        <div class=[`vas-interstitial-item-${name}`, `${get(label, 'action.URL', '') ? 'link-label' : ''}`] id=`vas-interstitial-item-${name}-${index}`>
			<if(get(label, 'action.URL', ''))>
				<a href=get(label, 'action.URL', '') target="_blank" class=`${ !isInitFocusSet ? 'vas-interstitial-init-focus' : ''}` on-click('onLabelClick', get(label, 'action', {}))>
					<for|textSpan, textSpanIdx| of=get(label, 'textSpans', [])>
						${textSpan.text}
					</for>
				</a>
				$ { isInitFocusSet = true; }
			</if>
			<else>
				<ux-textualdisplay model=label />
			</else>
		</div>
	</if>
</macro>

<macro|{ model, index }| name="renderVasFooterDataItem">

	<ebay-button
		class=["vas-interstitial-footer-btn", `${ !isInitFocusSet ? 'vas-interstitial-init-focus' : '' }`]
		id=`vas-interstitial-cta-${model.text ? model.text.toLowerCase() : index}`
		priority=`${model.type ? model.type.toLowerCase() : 'primary'}`
		fluid
		on-click("handleBtnClick", model.action, index)
		disabled=state.isBusy
		aria-label=(model.accessibilityText ? model.accessibilityText : '')>
		<if(state.btnClickedIdx === index)>
			<ebay-progress-spinner class="vas-interstitial-cta-btn-spinner" aria-label="Busy" />
		</if>
		<else>
			${model.text}
		</else>
	</ebay-button>
	$ { isInitFocusSet = true; }
</macro>

<macro|dataItem, index| name="vasLabelsValues">
	$ const labels = get(dataItem, 'model.labels', []);
	$ const values = get(dataItem, 'model.values', [])

    <div class='spoke-body-primary'>
        <div class='vas-spoke-body-primary-left'>
            <for|label, labelIdx| of=labels>
                    <ux-textualdisplay model=label />
            </for>
        </div>

        <div class='vas-spoke-body-primary-right'>
            <for|value, valueIdx| of=values>
                <ux-textualdisplay model=value />
            </for>
        </div>
    </div>
</macro>
